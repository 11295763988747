"use strict";

$(function () {
    var loader = $(".loader"),
        erase = $("i.erase"),
        endpoint = $(location).attr('origin'),

    // endpoint = "https://qav2.seiumb.com",
    data = {
        "term": [],
        "skip": 0,
        "take": 10,
        "resources": false,
        "offers": false,
        "solutions": false,
        "unionId": 2
    };

    // Search suggestions ----------------------------------------------------------------------
    var search = {
        suggesstionBox: $(".suggesstion-box"),
        loader: loader,
        erase: erase,
        searchBox: $("input#search"),
        redirect_key: "" + $('[data-redirect]').data("redirect"),
        seeAllOptionText: "see all...",
        init: function init() {
            this.getCards();
        },
        clear: function clear() {
            $("input").val("");
        },
        loaderShow: function loaderShow() {
            this.loader.show();
        },
        loaderHide: function loaderHide() {
            this.loader.hide();
        },
        eraseShow: function eraseShow() {
            this.erase.show();
        },
        eraseHide: function eraseHide() {
            this.erase.hide();
        },
        pressEnterURL: function pressEnterURL(term) {
            this.redirect(this.redirect_key);
        },
        populateBox: function populateBox(data, input) {
            var input = input,
                elements = "",
                input = $(input).siblings(".suggesstion-box");
            this.promised = this.promise(data, input);
            input.parent(".search").append(this.loader);
            this.loaderShow();

            this.promised.done(function () {
                if (this.promised.status != "404" && this.promised.status != "503") {
                    if (this.promised.responseJSON) if (this.promised.responseJSON.length > 0) {
                        $.each(this.promised.responseJSON, function (index) {
                            if (index <= 3) {
                                elements += "<a href=\"#\">" + this.Title + "</a>";
                            } else {
                                elements += "<a data-term=\"" + data.term + "\" href=\"#\">" + search.seeAllOptionText + "</a>";
                                return false;
                            }
                        });
                    } else if (this.promised.responseJSON.length == 0) {
                        //elements += `<a>No results were found</a>` 
                    }
                } else {
                        //elements += `<a>${promised.statusText}</a>` 
                    }
                this.loaderHide();
                input.html(elements).slideDown('fast');
                if ($('.new-search').length > 0) {
                    $('.new-search').addClass('box-opened');
                }
            }.bind(this));
        },
        redirect: function redirect(term) {
            sessionStorage.setItem('term', term);
            window.location.href = "" + endpoint + this.redirect_key;
        }, //redirect
        promise: function promise() {
            return $.ajax({
                data: data,
                dataType: 'json',
                async: true,
                type: 'POST',
                url: endpoint + "/api/SearchService/GetSuggestions"
            });
        },
        promised: ""
        //-------------------------------------------------------------------------------------------------
        // Search events
    };if ($(".search").length > 0) {
        var searchInput = $(".search input#search, .search input#search-mobile"),
            seachIcon = $(".search .fa-search, .search .search-icon");
        eraseIcon = $(".search i.erase"), destopSearchButton = $(".nav-right-desktop #searchButton");

        destopSearchButton.on('click', function (e) {
            e.preventDefault();
            destopSearchButton.toggleClass("opened");
            $('.new-search').fadeToggle();
            $(searchInput).focus();
            if (searchInput.val() !== "") {
                eraseIcon.trigger('click');
            }
        });

        searchInput.keyup(function (e) {
            var input = $(this);
            if (input.val().length > 2 && e.which != 13) {
                $(this).closest('.search').find('i.erase').show();
                $(this).closest('.search').addClass('erase-show');
                data.term = input.val();
                if (search.promised) search.promised.abort();
                search.loaderHide();
                search.populateBox(data, input);
            } else if (input.val().length > 2 && e.which == 13) {
                $(this).closest('.search').find('i.erase').show();
                $(this).closest('.search').addClass('erase-show');
                setGtm("", "" + input.val());
                search.redirect(input.val());
            } else if (input.val().length > 0) {
                $(this).closest('.search').find('i.erase').show();
                $(this).closest('.search').addClass('erase-show');
            } else if (input.val().length == 0) {
                $(this).closest('.search').find('i.erase').hide();
                $(this).closest('.search').removeClass('erase-show');
            }
        }).on("blur", function () {
            if (search.promised) search.promised.abort();
            search.loaderHide();
            setTimeout(function () {
                if (search.promised) {
                    search.promised.abort();
                }
                $(".suggesstion-box").hide();
            }, 350);
            if ($('.new-search').length > 0) {
                $('.new-search').removeClass('box-opened');
            }
        });
        seachIcon.on("click", function () {
            if ($(this).siblings('input').val().length > 2) {
                setGtm("", "" + $(this).siblings('input').val());
                search.redirect($(this).siblings('input').val());
            }
        });
        eraseIcon.on("click", function () {
            if (search.promised) {
                search.promised.abort();
            }
            search.loaderHide();
            $(this).closest('.search').find('input').val("");
            $(this).closest('.search').removeClass('erase-show');
            $(this).hide();
            setTimeout(function () {
                if (search.promised) {
                    search.promised.abort();
                }
                $(".suggesstion-box").hide();
            }, 350);
        });
        $(document).on("click", ".suggesstion-box a", function (e) {
            e.preventDefault();
            //here
            var term = $(this).text();
            if ($(this).text() == search.seeAllOptionText) {
                term = $(this).attr("data-term");
            }
            searchInput.val(term);
            search.redirect(term);
            setGtm("", "" + term);
        });
    } //if

    // Search results ----------------------------------------------------------------------
    var searchResults = {
        loader: loader,
        input: $("#search-big-input"),
        resultsCount: $(".results-count"),
        contextCards: $(".search-results .items"),
        seeMore: $(".more-items"),
        x: $("#search-big .fa-times"),
        searchIcon: $("#search-big .fa-search"),
        chkResources: $("#chkResources"),
        chkOffers: $("#chkOffers"),
        noResults: $(".no-results"),
        init: function init() {
            this.getCards();
        },
        clear: function clear() {
            this.input.val("");
            this.resultsCount.find(".number").html("0");
            this.contextCards.html("");
            this.seeMore.removeClass("show");
            this.loader.hide();
        },
        getCards: function getCards(data, el) {
            var el = el || "",
                data = data;
            this.loader.insertAfter(this.contextCards).show();
            if (el == "clear") {
                searchResults.contextCards.html("");
                data.skip = 0;
            }
            sessionStorage.setItem('term', data.term);
            var promised = this.promise(data);
            promised.done(function () {
                if (promised.readyState == 4 && promised.status == 200) {
                    this.contextCards.siblings($(".loader").remove());
                    if (promised.responseJSON.Cards.length > 0) {
                        this.resultsCount.find(".number").html(promised.responseJSON.Total);
                        this.contextCards.append(this.populateCards(promised.responseJSON.Cards));
                        searchResults.noResults.hide();
                        if ($(".search-results .items .item").length !== promised.responseJSON.Total) {
                            this.seeMore.addClass('show');
                        } else {
                            this.seeMore.removeClass('show');
                        }
                    } else {
                        this.resultsCount.find(".number").html("0");
                        this.seeMore.removeClass('show');
                        searchResults.noResults.show();
                    }
                }
            }.bind(this));
        }, //getCards
        populateCards: function populateCards(cards) {
            var htmlCards = "";
            $.each(cards, function (index, el) {
                var thumb = el.ThumbnailSrc != "" && el.ThumbnailSrc != null ? "<img src=\"" + (endpoint + el.ThumbnailSrc) + "\" alt=\"" + (el.ThumbnailAlt ? el.ThumbnailAlt : '') + "\" />" : "",
                    genre = el.Genre != "" && el.Genre != null ? "<p class=\"eye-brow\">" + el.Genre + "</p>" : "";
                style = el.Style == 0 ? "offer" : "";
                htmlCards += "<div class=\"item " + style + "\">\n                        <div class=\"content\">\n                            <div data-link=\"" + el.Cta + "\" class=\"resultGtm\">\n                                " + thumb + "\n                                <div class=\"item-text\">\n                                    " + genre + "\n                                    <p class=\"title\">" + el.Title + "</p>\n                                    <p class=\"d-none d-lg-block\">\n                                        " + el.Description + "\n                                    </p>\n                                </div>    \n                                <p class=\"d-lg-none d-md-block\">\n                                    " + el.Description + "\n                                </p>\n                            </div>\n                        </div>\n                    </div>";
            });
            return htmlCards;
        },
        promise: function promise(data) {
            return $.ajax({
                data: data,
                dataType: 'json',
                async: true,
                type: 'POST',
                url: endpoint + "/api/SearchService/Search"
            });
        } //requestData

        //-------------------------------------------------------------------------------------------------
        // Search results event page
    };if ($(".search-results").length > 0) {
        if (sessionStorage.getItem("term") !== null && sessionStorage.getItem("term") !== "") {
            data.term = sessionStorage.getItem("term");
            searchResults.getCards(data, "clear");
            searchResults.input.val(data.term);
        }
        searchResults.input.keyup(function (e) {
            searchResults.x.show();
            data.resources = searchResults.chkResources.is(':checked');
            data.offers = searchResults.chkOffers.is(':checked');
            if ($(this).val().length > 2 && e.which == 13) {
                data.term = $(this).val();
                searchResults.getCards(data, "clear");
                setGtm("", "" + $(this).val());
            }
        });
        searchResults.x.on("click", function (e) {
            e.preventDefault();
            searchResults.clear();
            $(this).hide();
        });
        searchResults.searchIcon.on("click", function (e) {
            e.preventDefault();
            data.resources = searchResults.chkResources.is(':checked');
            data.offers = searchResults.chkOffers.is(':checked');
            if (searchResults.input.val().length > 2) {
                data.term = searchResults.input.val();
                searchResults.getCards(data, "clear");
                setGtm("", "" + data.term);
            }
        });
        searchResults.seeMore.on("click", function (e) {
            e.preventDefault();
            data.skip = $(".search-results .items .item").length;
            searchResults.getCards(data);
        });
        //can manage n number of chk filters, must have value to read the property
        $(".chkfilter").on("change", function () {
            var dataValue = $(this).val();
            data[dataValue] = $(this).is(':checked');
            if (searchResults.input.val().length > 2) {
                data.term = searchResults.input.val();
                searchResults.getCards(data, "clear");
                // setGtm("",`${data.term}`);
            }
        });
    } else {
        searchResults.clear();
    }

    $(document).on("click", ".resultGtm .title , .resultGtm img, .resultGtm a", function (e) {
        e.preventDefault();
        var resultGtm = $(this).closest(".resultGtm");
        var itemText = resultGtm.find(".title").text();
        var anchor = resultGtm.find('.item-text a');
        setGtm("searchResult", $(this).is("img") ? "image|" + itemText : itemText, anchor);
        window.location.href = resultGtm.data("link");
    });

    function setGtm(type, searchText, element) {
        var gtm = {
            'event': 'search',
            'searchAction': 'result click'
        };
        if (type == "searchResult") {
            gtm.searchAction = 'result click';
        } else {
            gtm.searchAction = 'search entry';
        }
        gtm.searchText = searchText;

        if (element) dataLayerPush(gtm, element[0]);else dataLayerPush(gtm);
    }
});