"use strict";

$(function () {
	if ($(".form.registration").length > 0) {
		var $body = $("body");
		var $stepHeader = $("header .new-design");
		var $animContainer = $(".animation-container");

		formReset();
		setupBackLinkAction();

		$.each($(".form.registration form input[required], .form.registration form select[required] "), function () {
			if ($(this).val() != "") {
				$(this).addClass("valid");
			}
		});

		$('#registrationStep1').validate({
			onfocusout: function onfocusout(element) {
				this.element(element);
				setupAccentWhenValidForm("registrationStep1", 5);
			},
			ignore: ".ignore, :hidden",
			rules: {
				email: {
					isValidEmail: true,
					remote: {
						url: "/api/EmailValidation/EmailValidationRules",
						type: "GET",
						data: {
							email: function email() {
								return $("#email").val();
							},
							validateUsername: true,
							invalidMailError: $('#email').data('msg-email'),
							registeredUserError: $('#email').data('emailinuse'),
							reservedDomainError: $('#email').data('msg-hasreserveddomain')
						}
					}
				},
				password: {
					strongPassword: true
				},
				confirmPassword: {
					equalTo: "#password"
				}
			},
			errorElement: "div",
			errorPlacement: function errorPlacement(error, element) {
				setupErrorPlacementValidation(error, element);
			},
			submitHandler: function submitHandler(form, event) {
				//always if form is valid
				event.preventDefault();
				showSecondForm();
			}
		});

		$('#registrationStep2').validate({
			onfocusout: function onfocusout(element) {
				this.element(element);
				setupAccentWhenValidForm("registrationStep2", 7);
			},
			rules: {
				year: {
					isValidDate: true
				}
			},
			groups: {
				birthDate: "month day year"
			},
			errorElement: "div",
			errorPlacement: function errorPlacement(error, element) {
				setupErrorPlacementValidation(error, element);
			},
			submitHandler: function submitHandler(form, event) {
				event.preventDefault();
				var $forms = $('form');
				var optIn = $("#optIn").prop('checked');
				var data = $('#registrationStep1').serialize() + '&' + $('#registrationStep2').serialize() + '&optIn=' + optIn;
				var url = $("#registrationStep2").attr("action");

				$.ajax({
					type: 'POST',
					url: url,
					data: data,
					beforeSend: function beforeSend() {
						$(form).find(".fa-spinner").show();
					},
					complete: function complete() {
						$(form).find(".fa-spinner").hide();
					},
					success: function success(response, textStatus, xhr) {
						var model = response.Data.Model;
						var errors = response.Data.Errors;
						var statusCode = xhr.status;

						if (model.GtmAction) {
							executeGtm(model.GtmAction);
						}

						if (statusCode == 200 && response.Success && errors.IsProcessedSucessfully) {
							setRegistrationModalEvents(model.RequestedPage, model.IsCurrentMember);
						} else {
							if (statusCode == 200 && !response.Success) {
								if (model.UrlRedirection) {
									window.location.href = model.UrlRedirection;
								}
								if (errors.HasGeneralError) {
									$(".error-bar").addClass("show");
								}
								var currentStep = 2,
								    stepNumber = 2;
								var $serverErrors = $forms.find(".floating-error-messages .server-error");
								$serverErrors.remove();

								errors.ErrorList.forEach(function (error, index) {
									var field = error.FieldName;
									var $containerDiv = $("div[data-group='" + field + "'] .floating-error-messages");
									stepNumber = $("div[data-group='" + field + "']").parents(".form-step").data("step");

									error.ErrorType.forEach(function (errorType, index) {
										var errorMessage = "";
										switch (errorType) {
											case "InvalidCharacters":
												errorMessage = $("#" + field + "").data("msg-alphanumber");
												break;
											case "Length":
												errorMessage = $("#" + field + "").data("msg-maxlength");
												break;
											case "MinLength":
												errorMessage = $("#" + field + "").data("msg-minlength");
												break;
											case "EmailFormat":
												errorMessage = $("#" + field + "").data("msg-email");
												break;
											case "Warning":
												$("div[data-group='" + field + "'] .floating-messages").find('.warning').show();
												break;
											case "EmailInUse":
												errorMessage = $("#" + field + "").data("emailinuse");
												errorMessage = errorMessage.replace("{0}", model.Email);
												break;
											case "PasswordRequirement":
												errorMessage = $("#password").data("msg-strong-password");
												break;
											case "PasswordNotEqual":
												errorMessage = $("#" + field + "").data("msg-equalto");
												break;
											case "InvalidDate":
												errorMessage = $("#year").data("msg-isvaliddate");
												break;
											case "AgeRequirement":
												errorMessage = $("#year").data("msg-range");
												break;
											default:
												if (field == "birthDate") {
													field = "year";
												}
												errorMessage = $("#" + field + "").data("msg-required");
										}
										var $serverErrorDiv = $("<div class='server-error'>" + errorMessage + "</div>");
										$containerDiv.append($($serverErrorDiv));
									});
								});

								if (currentStep !== stepNumber) {
									formReset();
								}
							}
						}
					},
					error: function error(response) {
						window.location.href = "/";
					}
				});
			},
			invalidHandler: function invalidHandler(event, validator) {
				event.preventDefault();
				dataLayerPush({ 'event': 'registration', 'registrationAction': 'submit', 'registrationResult': 'failed' }, $(this));
			}
		});
	}

	/**
  * Resets the form back to the default state.
  */
	function formReset() {
		$(".js-form-step").removeClass("left leaving");
		$(".js-form-step").not('.js-form-step[data-step="1"]').addClass("hidden-step waiting");
		$('.js-form-step[data-step="1"]').removeClass("hidden-step");

		$animContainer.css({
			paddingBottom: $('.js-form-step[data-step="1"]').height() + 105
		});

		// Ensure top of form is in view
		$("html, body").animate({
			scrollTop: $stepHeader.offset().top
		});
		return false;
	}

	/**
  * Set Interaction/hide and show to go between form steps
  */
	function showSecondForm() {
		var $currentForm = $('.js-form-step[data-step="1"]');
		var $nextForm = $('.js-form-step[data-step="2"]');

		$body.addClass("freeze");

		// Ensure top of form is in view
		$("html, body").animate({
			scrollTop: $stepHeader.offset().top
		});

		// Hide current form fields
		$currentForm.addClass("leaving");
		setTimeout(function () {
			$currentForm.addClass("hidden-step");
		}, 500);

		// Animate container to height of form
		$animContainer.css({
			paddingBottom: $nextForm.height() + 105
		});

		// Show next form fields
		$nextForm.removeClass("hidden-step").addClass("coming").one("webkitTransitionEnd transitionend", function () {
			$nextForm.removeClass("coming waiting");
		});
		$('#address').blur();

		$body.removeClass("freeze");
		return false;
	}

	/**
  * Set action for back link
  */
	function setupBackLinkAction() {
		$(".back-link").on("click", function (e) {
			e.preventDefault();
			formReset();
		});
	}

	/**
  * Set registration modal events after registration successfull
  */
	function setRegistrationModalEvents(requestedPage, currentMember) {
		$("#btnModalRegistration").on("click", function (e) {
			e.preventDefault();
			window.location.href = requestedPage;
		});

		$('#registrationModal').on('hidden.bs.modal', function (e) {
			window.location.href = requestedPage;
		});

		if (currentMember) {
			$("#message-current-member").removeClass("hide");
		} else {
			$("#message-normal").removeClass("hide");
		}

		$('#registrationModal').modal('show');
	}

	/**
  * Execute GTM string function coming from BE
  */
	function executeGtm(functionText) {
		return Function('"use strict";' + functionText + '')();
	};

	if (!$("form#login").length > 0) {
		$("#password").on("focus", function () {
			$(".checklist li").addClass("list-item");
			passwordValidation("password");
		});
	}
	function passwordValidation(passwordId) {
		var passwordInp = $("#" + passwordId);

		var validationRegex = [{ regex: /.{8,}/ }, // min 8 letters,
		{ regex: /[a-zA-Z]/ }, // letters from a - z
		{ regex: /[0-9]/ }, // numbers from 0 - 9
		{ regex: /[^A-Za-z0-9]/ }];

		passwordInp.on("keyup", function () {
			var passwordChecklist = document.querySelectorAll(".list-item");
			validationRegex.forEach(function (item, i) {
				var trimVal = passwordInp.val();
				passwordInp.val(trimVal);
				var isValid = item.regex.test(trimVal);
				if (passwordChecklist.length > 0) {
					if (isValid) {
						if (i != 3) passwordChecklist[i].classList.add("checked");else passwordChecklist[i].classList.remove("checked");
					} else {
						if (i != 3) {
							passwordChecklist[i].classList.remove("checked");
						} else {
							passwordChecklist[i].classList.add("checked");
						}
					}
				}
				if ($("#password").val() == "") {
					$(".checklist li").removeClass("checked");
				}
			});
		});
	}
});