"use strict";

$(function () {
    // Scroll To
    if ($(".scroll").length > 0) {
        $(".scroll").click(function (e) {
            e.preventDefault();
            var dest = $(this).attr('href');
            var distance = $("#middle-menu").hasClass("fixed") ? 100 : 100;
            $('html, body').animate({
                scrollTop: $(dest).offset().top - distance
            }, 1000);
        });
    }

    if ($(".scrollSmooth").length > 0) {
        $(".scrollSmooth").click(function (e) {
            e.preventDefault();
            $('html, body').animate({ scrollTop: $(this.hash).offset().top }, 500);
        });
    }

    if ($(".representative .panel-cta").length > 0) {
        var back = '<div class="col-md-12"><a href="javascript:history.back()" title="title" class="form-nav"> <i aria-hidden="true" class="fa fa-chevron-left"> </i><span>BACK </span></a></div>';
        $(".representative .panel-cta").before(back);
    } //if

    setTimeout(function () {
        if ($(".addthis_inline_share_toolbox").html() == "" && $(".addthis_inline_share_toolbox").length > 0) {
            $(".attribution").hide();
        }
    }, 5000);
});

$.fn.scrollView = function () {
    return this.each(function () {
        var scroll_amount = $(this).data("scroll") ? $(this).data("scroll") : 0;
        $('html, body').animate({
            scrollTop: $(this).offset().top - scroll_amount
        }, 1000);
    });
};

var page = findGetParameter('goto');
if (page != undefined) {
    $(document).ready(function () {
        $('html, body').animate({
            scrollTop: $('#' + page).offset().top
        }, 500);
    });
}

function findGetParameter(parameterName) {
    var result = null,
        tmp = [];
    location.search.substr(1).split("&").forEach(function (item) {
        tmp = item.split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    });
    return result;
};

function hasQuestionMark(url) {
    if (url.indexOf("?") > -1) {
        return true;
    }
    return false;
}

function customScrollToByClass(elementToScroll) {
    if ($("." + elementToScroll).length > 0) {
        $('html, body').animate({
            scrollTop: $("." + elementToScroll).offset().top
        }, 1000);
    }
}

function fireTrackingOracleSP() {
    var productCode = $("#actionprocedurepar1").val();
    operationprocedureactioncta(productCode);
}

function getCookie(key) {
    var keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
    return keyValue ? keyValue[2] : null;
}

function showVideoLightbox(videoId) {
    $("#" + videoId).modal("show");
    $("#" + videoId).on('hidden.bs.modal', function (event) {
        var modal = $(this);
        var playerId = modal.find('.jwplayer').attr('id');
        if (playerId) {
            if (jwplayer(playerId).getState() == "playing" || jwplayer(playerId).getState() == "buffering") {
                jwplayer(playerId).play(false);
            }
            jwplayer(playerId).stop();
        } else {
            var playerYouTube = $("#" + videoId + "-frameId");
            playerYouTube[0].contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
            // $("#" + videoId).attr("src", $("#" + videoId + " iframe").attr("src"));
            // $("#" + videoId).attr('src', $(this).data( "src" )); 
            var memory = $(this).html();
            $(this).html(memory);
        }
    });
}